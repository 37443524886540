.gh-mainmenu {
    margin-bottom: 24px !important;
    &__item {
        $item:&;
        border-bottom:1px solid #ffffff !important;
        position: relative;
        padding: 16px 4px !important;
        &-icon {
            margin-right:0 !important;
        }
        &-text {
            padding:0 20px 0 8px !important;
            > span {
                font-size:0.9rem !important;
            }
            &-sub {
                display: block;
                font-size:0.65rem !important;
            }
            &--desc {
                span {
                    line-height: 1rem !important;
                }
            }
        }
        &-next {
            position: absolute;
            top: 50%;
            right: -10px;
            margin-right: 0 !important;
            transform: translateY(-50%);
            &-svg {
                font-size:45px !important;
            }
        }
        &--gold {
            #{$item}-icon {
                color:#FFD700;
            }
            #{$item}-text {
                span {
                    color:#FFD700 !important;
                }
            }
            #{$item}-next {
                color:#FFD700 !important;
            }
        }
    }
}