.gh-holeentry {
    margin:0 0 12px;
    &__meta {
        margin:0 -24px 12px;
        background:#304F80;
        padding:8px 24px;
        position: relative;
        &-text {
            font-size:1rem !important;
            &-info {
                margin-left:12px;
                font-size:0.70rem;
                display: inline-block;
                color:#f8f8f8;
                &-error {
                    font-size:18px !important;
                    color: #ff0000;
                    margin-left:8px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        &-icon {
            position: absolute !important;
            top:10px;
            right:12px;
            font-size:20px !important;
        }
        &-button {
            position: absolute !important;
            top:6px;
            right:6px;
            &-icon {
                font-size:16px !important;
                margin-right:4px;
            }
        }
    }
    &__radiogroup {
        margin-top:8px !important;
        &-label {
            text-transform: uppercase;
            font-size:0.85rem !important;
            margin-bottom:8px;
        }
        &-scroll {
            overflow-y:auto;
            margin: 0 -24px;
            padding-left: 24px;
            &-hold {
                width:380px;
            }
        }
    }
}