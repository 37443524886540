.gh-scrolltable {
  margin-bottom: 36px;
  max-height: 270px;

  th {
    background: #061738 !important;
  }
}
.gh__table {
  &-cell {
    &--diff {
      background:#79859E radial-gradient(farthest-corner at 10% 0,
        #42557E 0%, rgb(86, 98, 124) 50%,  #8DA2B0 100%) !important;
    }
  }
}