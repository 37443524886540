@charset "utf-8";
@import './assets/scss/vars.scss';
@import './assets/scss/mixins.scss';

@font-face {
    font-family: 'oregonregular';
    src: url('./assets/fonts/oregon-webfont.eot');
    src: url('./assets/fonts/oregon-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/oregon-webfont.woff2') format('woff2'),
         url('./assets/fonts/oregon-webfont.woff') format('woff'),
         url('./assets/fonts/oregon-webfont.ttf') format('truetype'),
         url('./assets/fonts/oregon-webfont.svg#oregonregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
html, body {
    height:100%;
}
* {
    box-sizing: border-box;
}
.gh-premium {
    color:#FFD700 !important;
}
body {
    margin:0;
    background:#0E3677 radial-gradient(farthest-corner at 10% 0,
    #0E3676 0%, #0E3676 50%,  rgb(50, 83, 150) 100%) !important;
    background-attachment: fixed !important;
    color:#ffffff;
    h1, h2, h3, h4, h5, h6 {
        font-family: 'oregonregular' !important;
    }
}
.gh-native {
    $native:&;
    .header {
        padding-top:20px;
    }
    .gh-content {
        margin-top:68px;
    }
    .fullpage {
        margin-top:20px;
    }
    .drawer__content {
        padding-top:20px;
    }
    &.--android {
        .header {
            padding-top:48px;
        }
        .gh-content {
            margin-top:96px;
        }
        .fullpage {
            margin-top:48px;
        }
        .drawer__content {
            padding-top:48px;
        }
    }
    .--hidenative {
        display:none;
    }
}
.gh-iphonex {
    .header {
        padding-top:36px;
    }
    .gh-content {
        margin-top:84px;
    }
    .fullpage {
        margin-top:36px;
    }
    .gh-appbar {
        padding-bottom: 16px;
    }
    .drawer__content {
        padding-top:36px;
    }
}

.fullpage {
    padding:12px;
}
.gh-content-component {
    p, td, th, li { 
        font-size: 0.875rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.01071em;
    }
}
.gh-content {
    margin-top:48px;
    padding:12px 12px 75px !important;
    &--contentful {
        p, td, th, li { 
            font-size: 0.875rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.01071em;
        }
        ol {
            padding:0 0 0 15px;
            margin:0;
            li {
                color:#79859E;
            }
        }
        img {
            max-width: 100%;
            border-radius: 5px;
            margin:12px 0;
        }
    }
    &__para {
        margin:12px 0 !important;
    }
    &__block {
        margin-top:24px;
        h2 {
            border-top:1px solid #fff;
            padding-top:12px;
        }
    }
}

.header {
    position:fixed !important;
    top:0;
    width:100%;
    background:rgba(14,54,118,0.9);
    z-index: 100;
}
.gh-overlays {
    &__title {
        padding:12px 0  !important;
        margin:0 12px 12px !important;
        border-bottom:1px solid #fff;
        position: relative;
        &-close {
            position: absolute;
            top:16px;
            right:0;
        }
    }
    &__content {
        padding:0 16px 24px !important;
    }
}
.gh-stroketable {
    background:rgba(14,54,118,0.9);
    max-width:500px;
    margin:0 auto;
    text-align: center;
}
.gh-tables {
    background:#79859E radial-gradient(farthest-corner at 10% 0,
        #42557E 0%, rgb(86, 98, 124) 50%,  #8DA2B0 100%) !important;
        border:1px solid #fff;
    tr {
        height:auto !important;
    }
    th {
        text-transform: uppercase;
        color:#fff;
        background:#061738;
    }
    th, td {
        padding:8px !important;
        border-left:1px solid #fff;
        border-bottom:1px solid #fff;
        height:auto !important;
        border:1px solid #fff;
    }
    &__course {
        font-size:0.75rem !important;
        display:inline-flex !important;
        &-icon {
            font-size:18px !important;
            margin-right:4px;
            margin-left:4px;
        }
    }
}
.gh-expand {
    &__panel {
        background:#061738 !important;
    }
}
.gh-history-chip {
    padding-top:24px;
    clear:both;
    display: block;
}
.gh-expand {
    &__details {
        display:block !important;
    }
}
.gh-flex-p {
    display:inline-flex !important;
    font-size: 13px !important;
    &__icon {
        font-size:18px !important;
        margin-right: 4px;
    }
}
.gh-chips {
    margin:12px 0;
    &__chip {
        margin:0 12px 12px 0;
    }
    &--whsMode {
        margin-top:0; 
        
        button {
            margin-bottom:12px;
            margin-left:0 !important;
        }
    }
}
.gh-heading {
    position: relative;
    &__bigsmall {
        width:auto;
        position:absolute !important;
        top:-6px;
        right:0;
    }
}
.gh-seemore {
    margin:24px 0 !important;
    &__badge {
        > span {
            background:#ff0000;
        }
    }
}
.gh-graphs {
    margin:12px auto;
    text-align: center;
    width:100%;
    overflow-x:auto;
    svg {
        
        color:#fff;
        path, line, text, circle {
            color:#fff;
            stroke:#fff;
        }
        path {
            stroke-width:2 !important;
        }
        text {
            font-size:0.75rem;
            fill:#fff;
            stroke:none;
            font-family: inherit;
        }
        .lineChart {
            path {
                stroke:#28844B !important;
                stroke-width: 3 !important;
            }
        }
    }
}
.gh-charts {
    margin:12px auto;
    text-align: center;
    width:100%;
    overflow-x:auto;
    text {
        fill:#fff !important;
        text-transform: uppercase;
        font-size: 0.65rem !important;
    }
}
body {
    .gh-avatar {
        &--image {
            background:transparent !important;
        }
    }
}

.MuiTooltip-popper {
    white-space: normal;
}

.gh-detailsMeta {
    $dm:&;
    padding:12px 60px 12px 0px;
    position: relative;
    min-height:70px;
    border-bottom:1px solid #fff;
    margin-bottom:12px;
    &__avatar {
        @include avatar;
        margin-bottom: 0;
    }
    &__name {
        font-size:1rem !important;
        display: flex !important;
        align-items: center;
        padding-right:40px;
        &-icon {
            font-size:16px !important;
            margin: 0 0 0 8px !important;
        }
    }
    &__metaline {
        $ml:&;
        display: flex !important;
        &-icon {
            font-size:18px !important;
            margin-right:4px;
        }
        &--lowkey {
            margin-top:4px !important;
            font-size:0.80rem !important;
        }
    }
    &__bigsmall {
        position:absolute !important;
        top:20px;
        right:0;
    }
    &__editicon {
        position:absolute !important;
        top:64px;
        right:0;
    }
    &--profile {
        min-height:95px;
        margin-bottom:6px;
        #{$dm}__avatar {
            width:70px !important;
            height:70px !important;
        }
    }
    a {
        color:#ffffff;
        text-decoration: none;
    }
}
.gh-detailsMetaSub {
    clear:both;
    border-top:1px solid #fff;
    padding-top:8px;
    &__date {
        font-size:0.8rem !important;
        display: inline-flex !important;
        align-items: center;
        &-icon {
            font-size:16px !important;
            margin: 0 4px 0 0 !important;
        }
    }
}
.gh-roundStats {
    margin-bottom:0px;
    padding:36px 0;
}
.gh-comments {
    .gh-heading {
        border-top:1px solid #fff;
        padding-top:16px;
        margin-top:0;
        margin-bottom: 12px;
    }
    form {
        margin-bottom: 24px;
    }
    &__comment {
        background:#061738 !important;
        &-avatar {
            background:#9c27b0 !important;
            border-radius: 0 !important;
            color:#fff !important;
        }
        &-date {
            font-size:0.75rem !important;
        }
        a {
            text-decoration: none;
        }
        margin-bottom: 4px;
    }
}
.gh-paraEnd {
    border-bottom:1px solid #fff;
    margin-bottom:12px;
    padding-bottom:24px;
}
.gh-stats {
    border-bottom:1px solid #fff;
    margin-bottom:12px;
    padding-bottom:12px;
    &__grid {
        padding:30px 0;
    }
}
.gh-scrolltabs {
    background:#0E3677 radial-gradient(farthest-corner at 10% 0,
    #0E3676 0%, #0E3676 50%,  #08193C 100%) !important;
    margin-bottom: 16px !important;
    &--extramargin {
        margin-bottom: 36px !important;
    }
}
.gh-roundwedges {
    transition: height 0.5s ease-out;
    .gh-heading {
        border-top:1px solid #fff;
        padding-top:16px;
    }
    .--para {
        margin-bottom: 12px;
    }
}
.gh-scorecards {
    margin-top:36px;
    .gh-heading {
        border-top:1px solid #fff;
        padding-top:16px;
    }
    &__card {
        margin-bottom: 16px;
        &-meta {
            background:radial-gradient(farthest-corner at 90% 0,
            rgba(131,138,154,0.7) 0%, rgba(9,128,169, 0.3) 50%, rgba(131,138,154,0.7) 100%);
            display:flex;
            align-items: center;
        }
        &-text {
            flex:1 1;
            display: flex !important;
            align-items: center;
            padding: 6px 12px;
            line-height: 0.8rem !important;
        }
        &-action {
            width:auto;
            border-radius:0 !important;
            box-shadow: none !important;
            border-left:1px solid #fff !important;
        }
        &-icon {
            width:20px;
            height:20px;
            display:flex;
            border:1px solid #061738;
            margin-right:8px;
            &White {
                background:#fff;
            }
            &Yellow {
                background:#FFFF00;
            }
            &Red {
                background:#FF0000;
            }
        }
    }
    .gh-scorecard {
        margin:12px 0;
    }
}
.gh-breadcrumbs {
    border-top:1px solid #fff;
    padding-top:8px;
    margin:12px 0;
    display: inline-flex !important;
    width:100%;
    &__link {
        color:#fff;
        font-size:0.75rem !important;
        margin:0 6px !important;
        text-decoration: none;
        &:first-child {
            margin-left:0 !important;
        }
    }
    &__text {
        font-size:0.75rem !important;
        margin:0 6px !important;
    }
}
.gh-images {
    max-width: 100%;
    margin: 0 auto 12px;
    border-radius:8px;
}
.gh-imagehold {
    margin: 0 auto 12px;
    border-radius:8px;
    background:#79859E radial-gradient(farthest-corner at 10% 0,
    #42557E 0%, rgb(86, 98, 124) 50%,  #8DA2B0 100%) !important;
    min-height:150px;
    display:flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
}
.gh-readmore {
    margin-bottom:24px !important;
    &__text {
        height:80px;
        overflow:hidden;
        margin-bottom:12px !important;
        &--open {
            height:auto;
            overflow: visible;
        }
    }
    &__link {
        display: flex;
        justify-content: right;
    }
}
.gh-icontext {
    display:flex !important;
    align-content: center;
    margin:12px 0 !important;
    text-transform: capitalize;
    &__icon {
        margin-right:8px;
    }
}
.gh-socials {
    padding:24px 0;
    &-block {
        color:#fff !important;
        text-decoration: none;
        display:block;
        text-align: center;
        &__text {
            display:block;
            width:100%;
            margin-top:-6px !important;
        }
        &__icon {
            font-size:46px !important;
        }
    }
}
.gh-reviews {
    margin-top:36px;
    .gh-heading {
        border-top:1px solid #fff;
        padding-top:16px;
    }
    &__review {
        background:#061738 !important;
        margin-bottom:16px !important;
    }
}
.gh-primary {
    color:$brand-green !important;
}
.gh-offer {
    display:flex;
    width:100%;
    border:1px solid #fff;
    margin:12px 0 36px;
    border-radius: 10px;
    align-items: center;
    padding:6px;
    &--reducedbottom {
        margin-bottom:24px;
    }
    &__side {
        margin:4px 10px 0 6px;
    }
    &__text {
        line-height:1rem !important;
        flex: 1;
    }
    &__price {
        margin:0 6px 0 12px !important;
        &--image {
            max-width:140px;
            img {
                margin:0 !important;
                display: block;
                min-height: 41px;
            }
        }
    }
    &__premium {
        color:#FFD700 !important;
    }
}
.gh-featured-content {
    margin-top:0px;
    .gh-heading {
        border-top:1px solid #fff;
        padding-top:16px;
    }
    &__card {
        margin: 0 auto 16px;
        background:#061738 !important;
    }
    &__address {
        text-transform: capitalize;
        margin-top:-8px !important;
    }
    &__tube {
        &.--alone {
            margin-top:36px;
        }
    }
}
.gh-popper {
    max-width:250px;
    background:#0E3677 radial-gradient(farthest-corner at 10% 0,
    #0E3676 0%, #0E3676 50%,  rgb(50, 83, 150) 100%) !important;
    border:1px solid #fff;
    &__link {
        color:#fff !important;
        &--premium {
            color:#FFD700 !important;
        }
    }
}
.gh-connections {
    $ghc:&;
    padding:0 0 6px;
    border-bottom:1px solid #fff;
    &__line {
        display: flex !important;
        justify-content: space-between;
        min-height: 32px;
        &-link {
            display: flex !important;
            align-items: center;
            color:#fff !important;
            text-decoration: none;
            margin-right: 24px;
            text-transform: uppercase;
            font-size:0.75rem !important;
            &-icon {
                margin-right:4px;
            }
        }
        &-connect {
            text-align: right;
        }
    }
    &--mine {
        #{$ghc}__line {
            justify-content: start;
        }
    }
}
.gh-ads {
    margin:60px 12px -48px;
    overflow:hidden;
}
.gh-actionline {
    display:flex !important;
    align-content:space-between;
    margin:36px 0 !important; 
    &__button {
        margin-right:6px !important;
        &:last-child {
            margin-left:6px;
            margin-right: 0 !important;
        }
    }
}
.gh-banner {
    position: relative;
    &__background {
        min-height: 200px;
        margin:0 !important;
    }
    &__feature {
        position: absolute;
        top:0;
        right:5%;
        max-height:225px;
        margin:0 !important;
    }
    h1 {
        position: absolute;
        bottom:0;
        left:5%;
        font-size: 24px !important;
        width: 186px;
    }
}
.gh-lists {
    &__item {
        &-avatar {
            background:#28844B !important;
            &-icon {
                color:#fff !important;
            }
        }
    }
}
.gh-disclaimer {
    margin:-12px 0 12px !important;
    font-size: 0.65rem !important;
    text-align: right;
    font-style: italic;
}
.gh-terms {
    font-size:0.85rem !important;
    &__link {
        color:#fff;
    }
}
.gh-brand-premium {
    color:$brand-premium;
}
@media only screen and (min-width: 1024px) {
    body {

        .header {
            max-width:1024px;
            margin-left:-512px;
            left:50%;
        }
        
        .fullpage {
            max-width: 1024px;
            margin:0 auto;
        }
        .gh-content {
            max-width:1024px;
            margin-left: auto;
            margin-right: auto;
        }
        .gh-offer {
            &__text {
                text-align: center !important;
            }
        }
        .gh-banner {
            
            &__feature {
                max-height:400px;
                right:15%;
            }
            h1 {
                width:275px;
                left:4%;
                font-size:36px !important;
            }
        }
        .gh-actionline {
            margin-top:0 !important;
        }
        .gh-herotext {
            $ht:&;
            display: flex !important;
            margin-bottom: 24px;
            &__hero {
                flex: 6;
            }
            &__text {
                flex:4;
                padding-left:24px;
                h2 {
                    margin-top:0 !important;
                }
            }
            &--reverse {
                flex-direction: row-reverse;
                .gh-herotext__hero {
                    flex: 5;
                }
                .gh-herotext__text {
                    flex:5;
                    padding-right:24px;
                    padding-left:0;
                    margin-top:0 !important;
                }
            }
        }
        .form {
            max-width:450px;
            margin:0 auto;
        }
        .gh-featured-content {
            &__tube {
                display:flex;
            }
            &__card {
                flex: 1;
                margin-right:12px;
                &:last-child {
                    margin-right:0;
                }
            }
        }
        .gh-roundwedges {
            width:50%;
            display:inline-block;
            padding:0 12px;
        }
        .gh-appbar {
            max-width: 1024px;
            left:50%;
            margin-left:-512px;
        }

        
    }

}