.gh-roundmeta {
    padding:12px;
    &__line {
        margin-bottom:6px !important;
    }
    &__emph {
        display: flex !important;
        align-items: center;
        margin-bottom:6px !important;
        &-span {
            font-size:1rem;
            display:inline-block;
            margin-right:6px;
        }
        &-icon {
            display:inline-block;
            font-size:20px !important;
        }
        &-button {
            margin-left:12px !important;
            &-svg {
                margin-right:4px;
            }
        }
    }
}