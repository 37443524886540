.gh-heading {
    font-family: 'oregonregular';
    margin:24px 0;
    font-weight: normal;
    &--central {
        text-align: center;
    }
    &--h1 {
        font-size:1.6rem;
    }
    &--nomarginbottom {
        margin-bottom:0;
    }
}