.gh-button-img {
  width: 100px;
  margin:  0px 0 0 4px !important;
  display: inline-block;
  padding:0 !important;
}

.gh-link {
  color: #28844B;
}

.gh-paypal-button {
  margin: 20px 0 !important;
}

.gh-scrolltabs {
  &__tab {
    &--form {
      margin-bottom: 36px;
      width:auto;
    }
  }
}