.gh-snackBar {
    &__message {
        display: flex;
        align-items: center;
        &-svg {
            margin-right:6px;
        }

        &-promo {
            margin-top:12px;
            padding-top:24px;
            border-top: 1px solid #fff;

            button {
                img {
                    width: 70px;
                }
            }
        }
    }
    > div {
        padding-left:12px;
        padding-right:12px;
        background:#d32f2f;
        color:#fff;
        margin:12px;
        border-radius:5px;
    }
    &--success,
    &--updated,
    &--added {
        > div {
            background:#28844B;
        }
    }
}