.gh-scorecard {
    overflow-y:auto;
    width:100%;
    &__header {
        margin:0 0 8px;
        &.--back {
            margin-top:16px;
        }
    }
    &__table {
        background:#79859E radial-gradient(farthest-corner at 10% 0,
        #42557E 0%, rgb(86, 98, 124) 50%,  #8DA2B0 100%) !important;
        border:1px solid #fff;
        min-width:300px;
    }
    &__row {
        height:auto !important;
    }
    &__cell {
        padding:8px !important;
        text-transform: uppercase;
        font-size: 0.75rem !important;
        &--score {
            padding:4px !important;
        }
        &-score {
            display: block;
            padding:4px;
            &--birdie {
                background:#C24442;
            }
            &--bogie {
                background:#415CBB;
            }
            &--dbogie {
                background:#30468E;
            }
            &--tbogie {
                background:#30468E;
                position: relative;
                &:after {
                    content: "";
                    border:1px solid #fff;
                    display: block;
                    position: absolute;
                    top:3px;
                    left:3px;
                    width:calc(100% - 6px);
                    height:calc(100% - 6px);
                }
            }
            &--albatros {
                background:#9ABE24;
            }
        }
    }
    td {
        text-align: center;
        min-width:30px;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        background:#79859E radial-gradient(farthest-corner at 10% 0,
        #42557E 0%, rgb(86, 98, 124) 50%,  #8DA2B0 100%) !important;
        &.gh-scorecard__cell--emp {
            background:#061738 !important;
            min-width:50px;
        }
    }
    th {
        border-bottom: 1px solid #fff;
        background:#061738;
    }
}