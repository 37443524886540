.gh-bigsmall {
    text-align: center;
    position: relative;
    padding-bottom:14px;
    &__value {
        font-size:1.6rem !important;
        line-height: 1.3rem !important;
        min-width: 30px;
    }
    &__label {
        font-size:0.55rem !important;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        position: absolute !important;
        bottom:0;
        left:0;
        width:100%;
        svg {
            font-size:10px;
            margin-right:2px;
        }
    }
}