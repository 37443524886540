.gh-searchfield {
    position: relative;
    &__results {
        position: absolute !important;
        top:58px;
        left:0;
        background:radial-gradient(farthest-corner at 10% 0,
    #0E3676 0%, #0E3676 50%,  #08193C 100%);
        border:1px solid #fff;
        padding-top:0 !important;
        padding-bottom:0 !important;
        z-index: 200;
        width:100%;
        &-listitem {
            border-top:1px solid #fff;
            padding:0 !important;
            &:first-child {
                border-top:none;
            }
            p {
                text-transform: capitalize;
                display: flex;
                align-items: center;
            }
            &--active {
                background:#838A9A;
            }
            &--requestcourse {
                > a {
                    
                }
                div {
                    padding-right:0 !important;
                }
                span {
                    text-align: right;
                    text-decoration: underline;
                    font-size: 0.85em !important;
                }
            }
        }
        &-listlink {
            padding:6px 12px !important;
            &--active {
                background:#838A9A;
            } 
        }
        &-icon {
            font-size:14px !important;
            margin-right:2px;
            &--second {
                margin-left:12px;
            }
        }
    }
}