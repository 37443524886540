@mixin loadingpanel {
    position:relative;
    min-height:250px;
    &-loader {
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        background:#f9f9f9;
        display:block;
        &-spinner {
            width:50px;
            position: absolute;
            top:50%;
            left:50%;
            margin-left:-25px;
            margin-top:-25px;
        }
    }
}

.clearHeader {
    padding-top:72px;
}
