.header {
    display:grid;
    grid-template-columns: 48px 1fr 48px;
    grid-gap: 0;
    align-items: center;
    position: relative;
    &__logo {
        text-align: center;
        margin-top:4px;
    }
    &__back {
        position: absolute;
        bottom: -30px;
        left: 3px;
        font-size: 42px !important;

    }
    &__person {
        &-icon {
            &.--premium {
                color:#FFD700;
            }
        }
    }
}
.drawer {
    background:#061738 !important;
    box-shadow:-4px 2px 30px #999 !important;
    position: relative;
    &__content {
        width:275px;
        padding:0 12px;
    }
    &__version {
        position: absolute;
        bottom:4px;
        right:12px;
        font-size:0.65rem !important;
        color:#ccc;
    }
}