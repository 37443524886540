@mixin avatar {
    float:left;
    background:$avatar-default !important;
    margin:0 12px 20px 0;
    color:$white !important;
    width:45px !important;
    height:45px !important;
    border-radius: 0 !important;
    text-transform: uppercase;
    &.--premium {
        background:$brand-premium !important;
        border:1px solid $brand-premium !important;
    }
}