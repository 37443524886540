@import '../../assets/scss/vars.scss';

.gh-appbar {
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    background:radial-gradient(farthest-corner at 90% 0,
    $brand-grey 0%, #0980A9 50%,  $brand-grey 100%);
    &__item {
        padding:2px !important;
        border-radius:0 !important;
        &-link {
            text-decoration: none;
            text-transform: uppercase;
            color:$white;
            padding-top:25px;
            &--disabled {
                opacity: 0.5;
            }
        }
        &-desc {
            font-size:0.7rem !important;
        }
        svg, &-badge {
            position: absolute;
            top:2px;
            left:50%;
            transform: translateX(-50%);
            font-size:30px;
        }
        &-badge {
            position: absolute !important;
            top:-10px;
            right:-10px;
            span {
                background-color: #ff0000 !important;
            }
        }
        &--history {
            svg {
                font-size:24px;
                top:4px;
            }
        }
    }
    &__gridcol {
        text-align: center;
        position: relative;
    }
    .flagsvg {
        width:38px;
        top:-18px;
    }
}