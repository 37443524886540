@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';
.gh-usermeta {
    padding:20px 60px 20px 0px;
    position: relative;
    &__avatar {
        @include avatar;
    }
    &__name {
        font-size:1rem !important;
        &--sub {
            font-size:0.85rem !important;
        }
    }
    &__bigsmall {
        position:absolute !important;
        top:20px;
        right:0;
    }
}