@import '../../assets/scss/vars.scss';
@import '../../assets/scss/mixins.scss';
.form {
    margin:0 24px;
    &--reducedmargin {
        margin:0 12px;
    }
    &--overlayed {
        margin:0;
    }
    &__label {
        color:$white !important;
    }
    &__input {
        color:$white !important;
    }
    &__field {
        &--imageuploader {
            text-align: center;
            position: relative;
            &-asis {
                @include avatar;
                width:75px !important;
                height:75px !important;
                text-align: center;
                float:none;
                margin:12px auto !important;
            }
            &-actions {
                display:inline-flex;
                align-items: center;
                .gh-tooltip {
                    font-size:18px !important;
                }
                &-delete {
                    font-size:18px !important;
                    margin-left:4px;
                }
            }
            &-loading {
                position: absolute;
                top:15px;
                left:50%;
                margin-left:-20px;
            }
            
        }
    }
    &__previewlabel {
        margin-bottom: 4px !important;
        text-align: center !important;
    }
    &__alts {
        &-para {
            margin:12px 0 !important;
            a, &-link {
                display: inline-block;
                margin-left:12px;
                color:$white;
                font-size:0.9rem;
            }
            &-link {
                text-decoration: underline;
                cursor:pointer;
            }
        }
        &-button {
            float:right;
            margin-right: -8px !important;
        }
        &--clearance {
            margin-bottom:60px;
        }
    }
    &__helper {
        text-align: right !important;
    }
    &__warning {
        display: flex;
        align-items: center;
        color:#ffa000 !important;
        &-icon {
            margin-right:4px;
            font-size:20px !important;
            color:#ffa000;
        }
    }
    &__formcontrol {
        position: relative;
    }
    &__group {
        &--radio {
            flex-direction:row !important;
        }
        &--numberentry {
            flex-direction:row !important;
            overflow-y: auto;
            &-entrylabel {
                $entrylabel:&;
                margin:0 6px 0 0 !important;
                &--selected {
                    #{ $entrylabel }-cover {
                        background:#28844B !important;
                    }
                }
                &-radio {
                    display: none !important;
                }
                &-cover {
                    width: 32px;
                    border: 1px solid #ffffff;
                    display: flex;
                    justify-content: center;
                    font-size: 1rem;
                    padding: 4px 0;
                }
            }
        }
    }
    &__fullwidth {
        width:100%;
    }
    &__action {
        &--extended {
            margin-bottom: 48px !important;
        }
        &-help {
            font-size: 0.75rem !important;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: "color 9999s ease-out, background-color 9999s ease-out";
        transition-delay: 9999s;
    }
}