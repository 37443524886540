.gh-roundwedge {
    background:radial-gradient(farthest-corner at 90% 0,
    rgba(131,138,154,0.7) 0%, rgba(9,128,169, 0.3) 50%, rgba(131,138,154,0.7) 100%);
    margin-bottom: 16px;

    &--isNine {
        background:radial-gradient(farthest-corner at 90% 0,
        rgba(36, 126, 63, 0.7) 0%, rgba(23, 170, 101, 0.3) 50%, rgba(107, 168, 107, 0.7) 100%);
    }

    &--hold {
        min-height: 105px;
        opacity: 0.5;
    }

    &__9hole {
        position: absolute !important;
        top:10px;
        right:12px;
        > span {
            height: 16px !important;
        padding: 0 2px !important;
        }
        
    }

    &__meta {
        padding:12px;
        margin-bottom: 0;
        &-bigsmall {
            right:12px;
        }
    }
    &__base {
        display: flex;
        &-extra {
            flex:1;
            display: inline-flex !important;
            align-items: center;
            padding-left:12px;  
            font-size:0.85rem !important;
            &-icon {
                margin-right:4px;
                font-size:18px !important;
            }
        }
        &-action {
            width:100px;
            border-left:1px solid #fff !important;
            box-shadow:none !important;
            border-radius: 0 !important;
        }
    }
    .gh-detailsMeta__name,
    .gh-detailsMeta__metaline {
        font-size:0.85rem !important;
        &.--indent {
            padding-right:40px;
        }
    }
}