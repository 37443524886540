.gh-livescores {
    position: fixed;
    top: 115px;
    right: 12px;
    width: auto;
    background: #061738;
    border: 1px solid #ffffff;
    box-shadow: 2px 2px 2px #061738;
    border-radius: 8px;
    z-index: 100;
    &__vals {
        width:55px;
        padding:12px 0 20px 0;
        float:left;
        &:last-child {
            margin-left:-8px;
        }
        &-small {
            bottom:6px;
        }
    }
}