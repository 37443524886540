.gh-loading {
    min-height:100vh;
    width:100%;
    position: fixed;
    top:0;
    left:0;
    background-color: rgba(8, 25, 60, 0.2);
    z-index: 102;
    &__spinner {
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-28px !important;
        margin-left:-28px !important;
        svg {
            font-size:90px !important;
        }
    }
}