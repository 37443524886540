.gh-recommended-friends {
    h3 {
        border-top: 1px solid #fff;
        padding-top: 16px;
    }

    &__entries {
        overflow-x: auto;
        max-width: 100%;
        display:flex;

        &-panel {
            padding-left:12px;
            padding-right:12px;
            width:33%;
            min-width: 300px;
            display: inline-block;
            margin-right:12px;
            background: radial-gradient(farthest-corner at 90% 0, rgba(131, 138, 154, 0.7) 0%, rgba(9, 128, 169, 0.3) 50%, rgba(131, 138, 154, 0.7) 100%)
        }
    }
    
}