.gh-choosecard {
    &__label {
        position: relative;

    }
    &__link {
        position: absolute !important;
        top:10px;
        right:-24px;
    }
}