.gh-logo {
    text-align: center;
    &--alone {
        margin:48px auto;
    }
    &--centralpage {
        height:100vh;
        display:grid;
        align-items:center;
    }
    &__link {
        text-decoration: none;
        color:#ffffff;
        display:block;
    }
    &__tagline {
        margin-top:-8px !important;
        margin-left:-15px !important;
    }
}