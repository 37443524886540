.gh-rating-display {
    display:inline-flex !important;
    align-items:center;
    margin-top:4px !important;
    &__text {
        margin-left:8px;
    }
    &__stars {
        font-size:20px !important;
    }
}